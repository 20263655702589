import { Controller } from "@hotwired/stimulus"
import showOrHide from "../src/showhide"


// Connects to data-controller="endpoint-form"
export default class extends Controller {
  static targets = [
    "keyField",
    "nameField",
  ]

  connect() {
    this.keyOverriden = false
  }

  //
  // Automatically generate a key from the name, as the user types
  //
  updateKey() {
    if (this.keyOverriden) {
      return
    }
    let name = this.nameFieldTarget.value;
    let key = name.toLowerCase().replace(/[^a-z0-9]/g, "_");
    this.keyFieldTarget.value = key;
  }

  //
  // Make sure that if the user updates the key manually, we don't
  // overwrite it if they change the name again
  //
  overrideKey(){
    this.keyOverriden = true
  }
}
