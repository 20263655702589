// Example usage:
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus"
import {CodeJar} from 'codejar';
import hljs from 'highlight.js'


export default class extends Controller {
  static values = {
    language: String
  }

  static targets = [
    "editor",
    "valueField"
  ]

  connect() {
    // initial highlighting, otherwise it won't be highlighted on page load
    hljs.highlightElement(this.editorTarget)

    // Setup codejar and highlight.js (hljs) to handle highlighting whenever a user is typing
    const highlight = editor => {
      hljs.highlightElement(editor)
    }
    this.jar = new CodeJar(this.editorTarget, highlight)

    // Keep the textarea field in sync with the editor
    this.jar.onUpdate(code => {
      this.valueFieldTarget.value = code
    });
  }

  disconnect() {
    this.jar.destroy();
  }
}
