import { Controller } from "@hotwired/stimulus"
import showOrHide from "../src/showhide"

// Connects to data-controller="api-credential-set"
export default class extends Controller {
  static targets = [
    "usernameConfig",
    "passwordConfig",
    "tokenConfig",
    "authenticationMethodField",
    "tokenFieldWrapper",
    "tokenQueryParamNameWrapper",
    "tokenFetchingEnabled",
    "tokenFetchingDisabled",
    "tokenFetchingFields",
  ]

  connect() {
    this.showAndHideConfigSections();
  }

  showAndHideConfigSections() {
    let method = this.authenticationMethodFieldTarget.value;
    let isTokenBased = method == "bearer" || method == "token_query_parameter";
    let isTokenFetchingEnabled = isTokenBased && this.tokenFetchingEnabledTarget.checked;
    let isTokenFetchingDisabled = isTokenBased && this.tokenFetchingDisabledTarget.checked;

    showOrHide(this.tokenFetchingFieldsTarget, isTokenFetchingEnabled)
    showOrHide(this.tokenFieldWrapperTarget, isTokenFetchingDisabled)
    showOrHide(this.tokenQueryParamNameWrapperTarget, method == "token_query_parameter")
    showOrHide(this.tokenConfigTarget, isTokenBased)
    showOrHide(this.usernameConfigTarget, method == "basic_auth")
    showOrHide(this.passwordConfigTarget, method == "basic_auth")
  }
}
