import { Controller } from "@hotwired/stimulus"
import showOrHide from "../src/showhide"
import enableDisable from "../src/enabledisable"


// Connects to data-controller="endpoint-form"
export default class extends Controller {
  static targets = [
    "formatField",
    "retrievalMethodField",
    "pollingField",
    "filesDirectoryConfig",
    "sftpConfig",
    "databaseFormGroup",
    "apiFormGroup",
    "sambaFormGroup",
    "logFileFormGroup",
    "pollingConfig",
    "receivingTcpConfig",
    "connectorFormGroup",
    "pollingCheckbox",
    "receivingHttpConfig",
    "dataCleaningField",
    "dataCleaningConfig",
    "astmConfig",
    "alwaysOpenConnectionCheckbox",
    "enableLineFeedCheckbox"
  ]

  connect() {
    this.showAndHideConfigSections()
  }

  showAndHideConfigSections() {
    let format = this.formatFieldTarget.value;
    let method = this.retrievalMethodFieldTarget.value;
    let polling = this.pollingFieldTarget.checked;
    let dataCleaningEnabled = this.dataCleaningFieldTarget.checked;
    let showPollingCheckbox = method && !method.includes("receiving")

    showOrHide(this.pollingCheckboxTarget, showPollingCheckbox)
    showOrHide(this.pollingConfigTarget, polling && showPollingCheckbox)
    showOrHide(this.databaseFormGroupTarget, method == "database")
    showOrHide(this.apiFormGroupTarget, method == "json_api")
    showOrHide(this.sftpConfigTarget, method == "sftp")
    showOrHide(this.sambaFormGroupTarget, method == "samba" || method == "log_file")
    showOrHide(this.filesDirectoryConfigTarget, method == "samba" || method == "log_file") // consider driving sftp by the same config
    showOrHide(this.logFileFormGroupTarget, method == "log_file")
    showOrHide(this.connectorFormGroupTarget, method == "receiving_connector")
    showOrHide(this.receivingTcpConfigTarget, (method == "receiving_tcp_ip" || method == "receiving_connector"))
    showOrHide(this.astmConfigTarget, (method == "receiving_tcp_ip" || method == "receiving_connector") && format == "astm")
    showOrHide(this.alwaysOpenConnectionCheckboxTarget, (method == "receiving_tcp_ip" || method == "receiving_connector") && (format == "astm" || format == "hl7"))
    showOrHide(this.enableLineFeedCheckboxTarget, (method == "receiving_tcp_ip" || method == "receiving_connector") && (format == "astm" || format == "hl7"))
    showOrHide(this.receivingHttpConfigTarget, method == "receiving_http")
    showOrHide(this.dataCleaningConfigTarget, dataCleaningEnabled)

    enableDisable(this.pollingCheckboxTarget, showPollingCheckbox)
    enableDisable(this.pollingConfigTarget, polling && showPollingCheckbox)
    enableDisable(this.databaseFormGroupTarget, method == "database")
    enableDisable(this.apiFormGroupTarget, method == "json_api")
    enableDisable(this.sftpConfigTarget, method == "sftp")
    enableDisable(this.sambaFormGroupTarget, method == "samba" || method == "log_file")
    enableDisable(this.filesDirectoryConfigTarget, method == "samba" || method == "log_file") // consider driving sftp by the same config
    enableDisable(this.logFileFormGroupTarget, method == "log_file")
    enableDisable(this.connectorFormGroupTarget, method == "receiving_connector")
    enableDisable(this.receivingTcpConfigTarget, (method == "receiving_tcp_ip" || method == "receiving_connector"))
    enableDisable(this.astmConfigTarget, (method == "receiving_tcp_ip" || method == "receiving_connector") && format == "astm")
    enableDisable(this.alwaysOpenConnectionCheckboxTarget, (method == "receiving_tcp_ip" || method == "receiving_connector") && (format == "astm" || format == "hl7"))
    enableDisable(this.enableLineFeedCheckboxTarget, (method == "receiving_tcp_ip" || method == "receiving_connector") && (format == "astm" || format == "hl7"))
    enableDisable(this.receivingHttpConfigTarget, method == "receiving_http")
    enableDisable(this.dataCleaningConfigTarget, dataCleaningEnabled)
  }
}
