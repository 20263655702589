import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = [
    "hostField",
    "usernameField",
    "passwordField",
    "privateKeyField",
    "passphraseField",
    "invalidIcon",
    "successIcon",
    "loadingIcon",
    "testButton",
    "errorMessage",
  ];
  static values = { testConnectionUrl: String };

  testSftpConnection(event) {
    event.preventDefault();
    leave(this.successIconTarget);
    leave(this.invalidIconTarget);
    enter(this.loadingIconTarget);
    this.testButtonTarget.classList.add("disabled");

    Rails.ajax({
      url: this.testConnectionUrlValue,
      type: 'post',
      data: this.formData(),
      success: this.onSuccess.bind(this),
      error: this.onError.bind(this),
      complete: this.onComplete.bind(this),
    })
  }

  onSuccess(result) {
    if (result.success) {
      enter(this.successIconTarget);
      this.hideErrorMessage()
    } else {
      enter(this.invalidIconTarget);
      this.showErrorMessage(result.error_message)
    }
  }

  onError (result) {
    enter(this.invalidIconTarget);
    this.showErrorMessage(result.substring(0, 30))
  }

  onComplete (_data) {
    leave(this.loadingIconTarget);
    this.testButtonTarget.classList.remove("disabled");
  }

  hideErrorMessage() {
    leave(this.errorMessageTarget)
  }

  showErrorMessage(message) {
    this.errorMessageTarget.innerText = message
    enter(this.errorMessageTarget)
  }

  formData() {
    return new URLSearchParams({
      host: this.hostFieldTarget.value,
      username: this.usernameFieldTarget.value,
      password: this.passwordFieldTarget.value,
      private_key: this.privateKeyFieldTarget.value,
      passphrase: this.passphraseFieldTarget.value
    }).toString();
  }

  // barcode value getter setters
  get testConnectionUrl() {
    return this.testConnectionUrlValue;
  }
}
