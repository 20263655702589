import { Controller } from "@hotwired/stimulus"
import showOrHide from "../src/showhide"
import hljs from 'highlight.js'

export default class extends Controller {
  static targets = [
    "content",
    "button",
  ]

  static values = {
    moreText: String,
    lessText: String,
    maxHeight: String,
    objectIdentifier: String
  }

  connect() {
    this.open = false;
    // showOrHide(this.buttonTarget, !this.contentFits())
  }

  toggle() {
    this.open === false ? this.show() : this.hide()
  }

  async show() {
    if(this.contentTarget.innerHTML.includes("...") && this.objectIdentifierValue){
      let baseUrl = `/read_more/${this.objectIdentifierValue}`
      let content = await fetch(baseUrl, { method: "GET" }).then(res => res.text())

      this.contentTarget.innerHTML = content

      let snippet = this.contentTarget.querySelector('pre code')

      let promise = Promise.resolve(null)
      
      if(snippet) promise = promise.then(() => hljs.highlightElement(snippet))

      promise.then(() => {
        this.open = true

        this.buttonTarget.innerHTML = this.lessTextValue;
        this.contentTarget.style.removeProperty("max-height");
      })

    }else{
      this.open = true

      this.buttonTarget.innerHTML = this.lessTextValue;
      this.contentTarget.style.removeProperty("max-height");
    }
  }

  hide() {
    this.open = false

    this.buttonTarget.innerHTML = this.moreTextValue;
    this.contentTarget.style.setProperty("max-height", this.maxHeightValue)
    this.contentTarget.scrollIntoView(false);
  }


  // = = =  WARNING = = =
  // The original implementation of this (using scrollHeight and clientHeight) ended up being a severe
  // performance issue for pages with many read-mores on them - most notably the Runs page. As such,
  // for now we're just ALWAYS showing the "show more" button, regardless of whether the content fits.
  // Not ideal, needs a fix at some point.
  //
  contentFits() {
    // this is just a guess, I don't actually know how these properties work,
    // seems to do the job decently
    return false;
    // return this.contentTarget.scrollHeight == this.contentTarget.clientHeight;
  }
}
