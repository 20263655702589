import { Controller } from "@hotwired/stimulus"
import Tagify from '@yaireo/tagify'

//
// Usage:
//   for select collection input type:
//
//   <%= form.tagify_collection_select :endpoint_ids, current_account.endpoints, :id, :name,
//        { selected: selected_fields },
//        { class: "form-control" }
//   %>
//
//   for text area input type:
//
//   <%= form.tagify_text_field :tag_list, current_account.available_tags, :name, :name,
//       { selected: selected_fields },
//       { class: "form-control" }
//   %>
export default class extends Controller {
  static values = {
    formtype: String,
    whitelist: Array,
    targetselectionid: String
  }
  connect() {
    if(this.formtypeValue === "text"){
      new Tagify(this.element, {
        whitelist: this.whitelistValue,
        dropdown: {
          position: "input",
          enabled : 0 // always opens dropdown when input gets focus
        },
        originalInputValueFormat: valuesArr => valuesArr.map(item => item.value)
      });
    } else if (this.formtypeValue === "select") {
      const targetValue = this.targetselectionidValue;
      let tagify = new Tagify(this.element, {
        whitelist: this.whitelistValue,
        enforceWhitelist: true,
        dropdown: {
          enabled : 0, // always opens dropdown when input gets focus
          highlightFirst: true,
          maxItems: 7
        },
        hooks: {
          beforeRemoveTag : function( tags ) {
            removeSelectedTag(tags[0].data)
            return Promise.resolve()
          }
      }
      });
      tagify.on('dropdown:select add', e => {
        addSelectedTag(e.detail.data)
      })

      populateSelectedTags();

      function populateSelectedTags() {
        document.querySelectorAll(`#${targetValue} option`).forEach(opt => {
          if (opt.getAttribute('selected')) {
            tagify.addTags([{
              id: opt.value,
              value: opt.text
            }])
          }
        });
      }

      function addSelectedTag(data) {
        document.querySelectorAll(`#${targetValue} option`).forEach(opt => {
          if (opt.value == data.id) {
            if(!opt.getAttribute('selected')) {
              opt.setAttribute('selected','selected')
              opt.selected = true
            }
          }
        });
      }
      function removeSelectedTag(data) {
        document.querySelectorAll(`#${targetValue} option`).forEach(opt => {
          if (opt.value == data.id) {
            if(opt.getAttribute('selected')) {
              opt.removeAttribute('selected')
              opt.selected = false
            }
          }
        });
      }
    }
  }
}
