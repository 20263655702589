export default function(element, boolean) {
  if (boolean) {
    inputs = element.querySelectorAll('input, select, textarea')
    inputs.forEach(input => {
      input.toggleAttribute("disabled", false);
    });
  } else {
    inputs = element.querySelectorAll('input, select, textarea')
    inputs.forEach(input => {
      input.toggleAttribute("disabled", true);
    });
  }
}
