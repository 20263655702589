/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails

require("@rails/activestorage").start()
import "@rails/actiontext"

// vanilla-js cocoon
import "@kollegorna/cocoon-vanilla-js"

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Jumpstart Pro & other Functionality
import "./src/**/*"
require("local-time").start()

import "chartkick/chart.js"

// cron-input-ui
import 'cron-input-ui/dist/cron-input-ui.min.js'
import 'cron-input-ui/dist/locales/en.js'

// Start Rails UJS
Rails.start()

