import { Controller } from "@hotwired/stimulus"
import showOrHide from "../src/showhide"
import { enter, leave } from "el-transition";

// Connects to data-controller="ssh-credential-set"
export default class extends Controller {
  static targets = [
    "hostnameField",
    "portField",
    "usernameField",
    "authenticationMethodField",
    "passwordField",
    "privateKeyField",
    "passphraseField",
    "preventConcurrencyField",
    "usernameConfig",
    "passwordConfig",
    "privateKeyConfig",
    "passphraseConfig",
    "invalidIcon",
    "successIcon",
    "loadingIcon",
    "testButton",
    "errorMessage",
  ]

  static values = { testConnectionUrl: String }

  testSshConnection(event) {
    event.preventDefault();
    leave(this.successIconTarget);
    leave(this.invalidIconTarget);
    enter(this.loadingIconTarget);
    this.testButtonTarget.classList.add("disabled");
    Rails.ajax({
      url: this.testConnectionUrlValue,
      type: 'post',
      data: this.formData(),
      success: this.onSuccess.bind(this),
      error: this.onError.bind(this),
      complete: this.onComplete.bind(this),
    })
  }

  onSuccess(result) {
    if (result.success) {
      enter(this.successIconTarget);
      this.hideErrorMessage()
    } else {
      enter(this.invalidIconTarget);
      this.showErrorMessage(result.error_message)
    }
  }

  onError (result) {
    enter(this.invalidIconTarget);
    this.showErrorMessage(result.substring(0, 30))
  }

  onComplete (_data) {
    leave(this.loadingIconTarget);
    this.testButtonTarget.classList.remove("disabled");
  }

  hideErrorMessage() {
    leave(this.errorMessageTarget)
  }

  showErrorMessage(message) {
    this.errorMessageTarget.innerText = message
    enter(this.errorMessageTarget)
  }

  formData() {
    return new URLSearchParams({
      hostname: this.hostnameFieldTarget.value,
      port: this.portFieldTarget.value,
      username: this.usernameFieldTarget.value,
      authentication_method: this.authenticationMethodFieldTarget.value,
      password: this.passwordFieldTarget.value,
      private_key: this.privateKeyFieldTarget.value,
      passphrase: this.passphraseFieldTarget.value
    }).toString();
  }

  connect() {
    this.showAndHideConfigSections();
  }

  showAndHideConfigSections() {
    let method = this.authenticationMethodFieldTarget.value;

    showOrHide(this.privateKeyConfigTarget, method == "private_key")
    showOrHide(this.passphraseConfigTarget, method == "private_key")
    showOrHide(this.passwordConfigTarget, method == "password")
  }
}
