import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use';

//
// Copied and modified from the Comply Group Platform project's Slideover component,
// which is an overlay rather than a sidebar that pushes existing content to the side.
//
// Usage:
//
//    = link_to "Click me", paper_trail_path(@record.to_sgid),
//              method: :get,
//              data: { action: "slideout#open" }
//
// The response to `paper_trail_path` (or whatever path you give it) is expected to be a `turbo_stream` response,
// that does two things:

//   = turbo_stream.update "slideout-title", "Slideout title!"
//   = turbo_stream.update "slideout-content" do # render whatever content you need in here
//
// See paper_trail/show.turbo_stream.slim for an example
//
// Connects to data-controller="slideout"
export default class extends Controller {
  static targets = [
    "panel",
    "overlay",
    "title",
    "content",
  ]

  connect() {
    console.log("slideout connected!")
    // Note: unsure how to make transitions work on the overlays,
    // so exiting the panel currently has no animation, since the overlay
    // contains the panel, probably not important for now
    useTransition(this, { element: this.panelTarget })
  }

  open() {
    // if (this.panelTarget.classList.contains('hidden')) {
    this.clearContent()
    this.enter()
    this.overlayTargets.forEach(target => target.classList.remove('hidden'));
    // } else {
    //   this.close()
    // }
  }

  close() {
    this.clearContent()
    this.overlayTargets.forEach(target => target.classList.add('hidden'));
    this.leave()
  }

  closeFromOutside(event) {
    if(!this.panelTarget.contains(event.target)){
      this.close()
    }
  }

  clearContent() {
    this.titleTarget.innerHTML = ""
    this.contentTarget.innerHTML = ""
  }

  handleEsc(event) {
    if(this.transitioned && event.key === 'Escape') {
      this.close();
    }
  }
}
