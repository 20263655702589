import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["endpointLogs"]
  static values = {
    endpointId: String // TCP Endpoint to Subscribe
  }

  connect() {
    this.subscription = consumer.subscriptions.create({ channel: "EndpointTCPLogChannel", id: this.endpointIdValue }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })

    console.log(`collecting logs for Endpoint ${this.endpointIdValue}`)
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  _connected() {
  }

  _disconnected() {
  }

  _received(data) {
    // This escapes HTML looking blocks like <STX> / <ENQ> / <ACK> blocks
    const escaped = new Option(data).innerHTML;
    this.endpointLogsTarget.innerHTML += `<p>${escaped}</p>`
  }
}
