// Example usage:
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus"
import hljs from 'highlight.js'

//
// Purpose: highlights all code snippets on the page
//
// Usage:
//  <%= render "shared/code_snippet", code: "puts 'my ruby code'", language: "ruby" %>
//
export default class extends Controller {
  connect() {
    // highlight all `pre code` blocks
    // console.log("Starting code snippet highlighting")
    hljs.highlightAll()
    // console.log("Finished code snippet highlighting")
  }
}
